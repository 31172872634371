import {PermissionCodeEnum} from "@/types/enums/permissionEnum";
import {t} from '@/plugins/i18n'

export const DefaultRoles = [
    {
        role_name: '管理员',
        value: 'admin'
    },
    {
        role_name: '财务',
        value: 'finance'
    },
    {
        role_name: '运营',
        value: 'operate'
    }
]

export const getPermissionDefaultMenus = () => {
    return [
        {
            label: t('home'),
            icon: 'kopic-shouye',
            code: PermissionCodeEnum.Dashboard,
            functions: [
                {
                    label: t('common.withdrawCash'),
                    code: PermissionCodeEnum.DashboardWithdrawCash,
                    checked: true
                }
            ],
            children: []
        },
        {
            label: t('order.center'),
            icon: 'kopic-dingdan',
            code: PermissionCodeEnum.Transaction,
            functions: [],
            children: [
                {
                    label: t('routes.business.orderDetail'),
                    code: PermissionCodeEnum.Bill,
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.BillView,
                            checked: true
                        },
                        {
                            label: t('sys.priceTemplate.detail'),
                            code: PermissionCodeEnum.BillDetail,
                            checked: true,
                        },
                        {
                            label: t('common.refunded'),
                            code: PermissionCodeEnum.BillRefund,
                            checked: true
                        },
                        {
                            label: t('common.export'),
                            code: PermissionCodeEnum.BillExport,
                            checked: true
                        }
                    ]
                },
                // {
                //     label: t('routes.business.merchantSettlement'),
                //     code: PermissionCodeEnum.Settlement,
                //     functions: [
                //         {
                //             label: t('common.view'),
                //             checked: true,
                //             code: PermissionCodeEnum.SettlementView
                //         },
                //         {
                //             label: t('common.export'),
                //             checked: true,
                //             code: PermissionCodeEnum.SettlementExport
                //         }
                //     ]
                // },
                {
                    label: t('order.reissue'),
                    code: PermissionCodeEnum.BillReissue,
                    functions: [
                        {
                            label: t('common.view'),
                            checked: true,
                            code: PermissionCodeEnum.BillReissueView
                        }
                    ]
                },
                {
                    label: t('routes.business.chargeback'),
                    code: PermissionCodeEnum.Chargeback,
                    functions: [
                        {
                            label: t('common.view'),
                            checked: true,
                            code: PermissionCodeEnum.ChargebackView
                        },
                        {
                            label: t('common.export'),
                            checked: true,
                            code: PermissionCodeEnum.ChargebackExport
                        }
                    ]
                },
                {
                    label: t('replace.payment'),
                    code: PermissionCodeEnum.ReplacePayment,
                    functions: [
                        {
                            label: t('common.view'),
                            checked: true,
                            code: PermissionCodeEnum.ReplacePaymentView
                        },
                        {
                            label: t('common.export'),
                            checked: true,
                            code: PermissionCodeEnum.ReplacePaymentExport
                        }
                    ]
                }
            ]
        },
        // },
        {
            label: t('common.finance'),
            icon: 'kopic-zhifuzhongxin',
            code: PermissionCodeEnum.Transaction,
            functions: [],
            children: [
                {
                    label: t('routes.business.tranDetail'),
                    code: PermissionCodeEnum.Business,
                    functions: [
                        {
                            label: t('common.view'),
                            checked: true,
                            code: PermissionCodeEnum.BusinessView
                        },
                        {
                            label: t('common.export'),
                            checked: true,
                            code: PermissionCodeEnum.BusinessExport
                        }
                    ]
                },
                {
                    label: t('recharge.history'),
                    code: PermissionCodeEnum.RechargeHistory,
                    functions: [
                        {
                            label: t('common.view'),
                            checked: true,
                            code: PermissionCodeEnum.RechargeHistoryView
                        }
                    ]
                },
                {
                    label: t('withdraw.cash'),
                    code: PermissionCodeEnum.WithdrawCash,
                    functions: [
                        {
                            label: t('common.view'),
                            checked: true,
                            code: PermissionCodeEnum.WithdrawCashView
                        },
                        {
                            label: t('common.export'),
                            checked: true,
                            code: PermissionCodeEnum.WithdrawCashExport
                        }
                    ]
                }
            ]
        },
        {
            label: t('sys.analyse.head'),
            icon: 'kopic-fenxi',
            code: PermissionCodeEnum.Analyze,
            functions: [],
            children: [
                {
                    label: t('common.water'),
                    code: PermissionCodeEnum.AnalyzeStream,
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.AnalyzeStreamView,
                            type: 'tab',
                            checked: true
                        }
                    ]
                },
                {
                    label: t('common.payer'),
                    code: PermissionCodeEnum.AnalyzePayUser,
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.AnalyzePayUserView,
                            type: 'tab',
                            checked: true
                        }
                    ]
                },
                {
                    label: t('conversion.rate'),
                    code: PermissionCodeEnum.AnalyzeRate,
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.AnalyzeRateView,
                            type: 'tab',
                            checked: true
                        }
                    ]
                },
                {
                    label: t('pay.rank'),
                    code: PermissionCodeEnum.AnalyzePaymentRank,
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.AnalyzePaymentRankView,
                            type: 'tab',
                            checked: true
                        }
                    ]
                },
                {
                    label: t('visitor.value'),
                    code: PermissionCodeEnum.AnalyzeVisits,
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.AnalyzeVisitsView,
                            type: 'tab',
                            checked: true
                        }
                    ]
                }
            ]
        },
        {
            label: t('common.account'),
            icon: 'kopic-xitongquanxian',
            code: PermissionCodeEnum.Permission,
            functions: [],
            children: [
                {
                    label: t('user.permission'),
                    code: PermissionCodeEnum.PermissionUser,
                    links: [PermissionCodeEnum.PermissionUserDetail],
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.PermissionUserView,
                            checked: true,
                        },
                        {
                            label: t('common.editText'),
                            code: PermissionCodeEnum.PermissionUserViewEdit,
                            checked: true,
                        },
                        {
                            label: t('common.invite'),
                            code: PermissionCodeEnum.PermissionUserInvite,
                            checked: true,
                        }
                    ]
                },
                {
                    label: t('role.permission'),
                    code: PermissionCodeEnum.PermissionCreateRole,
                    links: [PermissionCodeEnum.PermissionRoleDetail],
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.PermissionCreateRoleView,
                            checked: true,
                        },
                        {
                            label: t('common.editText'),
                            code: PermissionCodeEnum.PermissionCreateRoleEdit,
                            checked: true,
                        },
                        {
                            label: t('common.creates'),
                            code: PermissionCodeEnum.PermissionCreateRoleNew,
                            checked: true,
                        }
                    ]
                },
                {
                    label: t('common.companySetting'),
                    code: PermissionCodeEnum.PermissionMerchantSetting,
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.PermissionMerchantSettingView,
                            checked: true,
                        },
                        {
                            label: t('common.editText'),
                            code: PermissionCodeEnum.PermissionMerchantSettingEdit,
                            checked: true,
                        }
                    ]
                },
                {
                    label: t('order.payment'),
                    code: PermissionCodeEnum.OrderPurchase,
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.OrderPurchaseView,
                            checked: true,
                        },
                        {
                            label: t('common.submit'),
                            code: PermissionCodeEnum.OrderPurchaseSubmit,
                            checked: true
                        }
                    ]
                },
                {
                    label: t('order.paymentHistory'),
                    code: PermissionCodeEnum.OrderPurchaseHistory,
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.OrderPurchaseHistoryView,
                            checked: true,
                        }
                    ]
                },
                {
                    label: t('order.paymentBill'),
                    code: PermissionCodeEnum.OrderPurchaseBill,
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.OrderPurchaseBillView,
                            checked: true,
                        }
                    ]
                }
            ]
        },
        {
            label: t('common.shop'),
            icon: 'kopic-shangpin',
            code: PermissionCodeEnum.Goods,
            functions: [],
            fromRouteCode: PermissionCodeEnum.App,
            children: [
                {
                    label: t('sys.store.types.type1'),
                    code: PermissionCodeEnum.VirtualGoods,
                    type: 'page',
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.VirtualGoodsView,
                            checked: true
                        },
                        {
                            label: t('common.editText'),
                            code: PermissionCodeEnum.VirtualGoodsEdit,
                            checked: true
                        }
                    ]
                },
                {
                    label: t('sys.store.good.category'),
                    code: PermissionCodeEnum.GoodsClassify,
                    type: 'page',
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.GoodsClassifyView,
                            checked: true
                        },
                        {
                            label: t('common.editText'),
                            code: PermissionCodeEnum.GoodsClassifyEdit,
                            checked: true
                        }
                    ]
                },
                {
                    label: t('goods.manage'),
                    code: PermissionCodeEnum.GoodsManage,
                    type: 'page',
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.GoodsManageView,
                            checked: true
                        },
                        {
                            label: t('common.editText'),
                            code: PermissionCodeEnum.GoodsManageEdit,
                            checked: true
                        }
                    ]
                },
                {
                    label: t('store.currency'),
                    code: PermissionCodeEnum.VirtualCurrency,
                    type: 'page',
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.VirtualCurrencyView,
                            checked: true
                        },
                        {
                            label: t('common.editText'),
                            code: PermissionCodeEnum.VirtualCurrencyEdit,
                            checked: true
                        }
                    ]
                },
                {
                    label: t('month.weekCard'),
                    code: PermissionCodeEnum.MonthCard,
                    type: 'page',
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.MonthCardView,
                            checked: true
                        },
                        {
                            label: t('common.editText'),
                            code: PermissionCodeEnum.MonthCardEdit,
                            checked: true
                        }
                    ]
                },
                {
                    label: t('activity.replay'),
                    code: PermissionCodeEnum.ActivityReplay,
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.ActivityReplayView,
                            checked: true
                        },
                        {
                            label: t('common.editText'),
                            code: PermissionCodeEnum.ActivityReplayEdit,
                            checked: true
                        }
                    ]
                },
                {
                    label: t('common.recharge'),
                    code: PermissionCodeEnum.Recharge,
                    type: 'page',
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.RechargeView,
                            checked: true
                        },
                        {
                            label: t('common.editText'),
                            code: PermissionCodeEnum.RechargeEdit,
                            checked: true
                        }
                    ]
                },
                {
                    label: t('recharge.face'),
                    code: PermissionCodeEnum.RechargeValue,
                    type: 'page',
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.RechargeValueView,
                            checked: true
                        },
                        {
                            label: t('common.editText'),
                            code: PermissionCodeEnum.RechargeValueEdit,
                            checked: true
                        }
                    ]
                }
            ]
        },
        {
            label: t('sys.store.activity.menuName'),
            icon: 'kopic-yingxiao',
            code: PermissionCodeEnum.Activity,
            functions: [],
            children: [
                {
                    label: t('common.xy'),
                    code: PermissionCodeEnum.ActivityXy,
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.ActivityXyView,
                            checked: true
                        },
                        {
                            label: t('common.creates'),
                            code: PermissionCodeEnum.ActivityXyCreate,
                            checked: true
                        },
                        {
                            label: t('common.editText'),
                            code: PermissionCodeEnum.ActivityXyEdit,
                            links: [PermissionCodeEnum.ActivityXyCreate],
                            checked: true
                        }
                    ]
                },
                {
                    label: t('exhausted.charge'),
                    code: PermissionCodeEnum.ActivityAddUp,
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.ActivityAddUpView,
                            checked: true
                        },
                        {
                            label: t('common.creates'),
                            code: PermissionCodeEnum.ActivityAddUpCreate,
                            checked: true
                        },
                        {
                            label: t('common.editText'),
                            code: PermissionCodeEnum.ActivityAddUpEdit,
                            links: [PermissionCodeEnum.ActivityAddUpCreate],
                            checked: true
                        },
                        {
                            label: t('common.replay'),
                            code: PermissionCodeEnum.ActivityAddUpReplay,
                            checked: true
                        }
                    ]
                },
                {
                    label: t('common.activityFirst'),
                    code: PermissionCodeEnum.ActivityFirstBuy,
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.ActivityFirstBuyView,
                            checked: true
                        },
                        {
                            label: t('common.creates'),
                            code: PermissionCodeEnum.ActivityFirstBuyCreate,
                            checked: true
                        },
                        {
                            label: t('common.editText'),
                            code: PermissionCodeEnum.ActivityFirstBuyEdit,
                            links: [PermissionCodeEnum.ActivityFirstBuyCreate],
                            checked: true
                        }
                    ]
                },
                {
                    label: t('goods.limit'),
                    code: PermissionCodeEnum.ActivityLimit,
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.ActivityLimitView,
                            checked: true
                        },
                        {
                            label: t('common.creates'),
                            code: PermissionCodeEnum.ActivityLimitCreate,
                            checked: true
                        },
                        {
                            label: t('common.editText'),
                            code: PermissionCodeEnum.ActivityLimitEdit,
                            links: [PermissionCodeEnum.ActivityLimitCreate],
                            checked: true
                        }
                    ]
                },
                {
                    label: t('recharge.discount'),
                    code: PermissionCodeEnum.ActivityRecharge,
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.ActivityRechargeView,
                            checked: true
                        },
                        {
                            label: t('common.creates'),
                            code: PermissionCodeEnum.ActivityRechargeCreate,
                            checked: true
                        },
                        {
                            label: t('common.editText'),
                            code: PermissionCodeEnum.ActivityRechargeEdit,
                            links: [PermissionCodeEnum.ActivityRechargeCreate],
                            checked: true
                        }
                    ]
                },
                {
                    label: t('common.sign'),
                    code: PermissionCodeEnum.ActivitySignIn,
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.ActivitySignInView,
                            checked: true
                        },
                        {
                            label: t('common.creates'),
                            code: PermissionCodeEnum.ActivitySignInCreate,
                            checked: true
                        },
                        {
                            label: t('common.editText'),
                            code: PermissionCodeEnum.ActivitySignInEdit,
                            links: [PermissionCodeEnum.ActivitySignInCreate],
                            checked: true
                        },
                        {
                            label: t('common.replay'),
                            code: PermissionCodeEnum.ActivitySignInReplay,
                            checked: true
                        }
                    ]
                },
                {
                    label: t('common.conRecharge'),
                    code: PermissionCodeEnum.ActivityConsecutive,
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.ActivityConsecutiveView,
                            checked: true
                        },
                        {
                            label: t('common.creates'),
                            code: PermissionCodeEnum.ActivityConsecutiveCreate,
                            checked: true
                        },
                        {
                            label: t('common.editText'),
                            code: PermissionCodeEnum.ActivityConsecutiveEdit,
                            links: [PermissionCodeEnum.ActivityConsecutiveCreate],
                            checked: true
                        },
                        {
                            label: t('common.replay'),
                            code: PermissionCodeEnum.ActivityConsecutiveReplay,
                            checked: true
                        }
                    ]
                },
                {
                    label: t('common.lottery'),
                    code: PermissionCodeEnum.ActivityLottery,
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.ActivityLotteryView,
                            checked: true
                        },
                        {
                            label: t('common.creates'),
                            code: PermissionCodeEnum.ActivityLotteryCreate,
                            checked: true
                        },
                        {
                            label: t('common.editText'),
                            code: PermissionCodeEnum.ActivityLotteryEdit,
                            links: [PermissionCodeEnum.ActivityLotteryCreate],
                            checked: true
                        },
                        {
                            label: t('common.replay'),
                            code: PermissionCodeEnum.ActivityLotteryReplay,
                            checked: true
                        }
                    ]
                },
                {
                    label: t('convert.key'),
                    code: PermissionCodeEnum.ConvertKey,
                    type: 'page',
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.ConvertKeyView,
                            checked: true
                        },
                        {
                            label: t('common.creates'),
                            code: PermissionCodeEnum.CoverKeyCreate,
                            checked: true
                        }
                    ]
                },
                {
                    label: t('full.discount'),
                    code: PermissionCodeEnum.ActivityFullDiscount,
                    type: 'page',
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.ActivityFullDiscountView,
                            checked: true
                        },
                        {
                            label: t('common.creates'),
                            code: PermissionCodeEnum.ActivityFullDiscountCreate,
                            checked: true
                        },
                        {
                            label: t('common.editText'),
                            code: PermissionCodeEnum.ActivityFullDiscountEdit,
                            links: [PermissionCodeEnum.ActivityFullDiscountCreate],
                            checked: true
                        },
                        {
                            label: t('common.replay'),
                            code: PermissionCodeEnum.ActivityFullDiscountReplay,
                            checked: true
                        }
                    ]
                },
                {
                    label: t('invite.code'),
                    code: PermissionCodeEnum.ActivityInviteCode,
                    type: 'page',
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.ActivityInviteCodeView,
                            checked: true
                        },
                        {
                            label: t('common.editText'),
                            code: PermissionCodeEnum.ActivityInviteCodeEdit,
                            checked: true
                        }
                    ]
                }
                // {
                //     label: '优惠券',
                //     code: PermissionCodeEnum.ActivityCoupon,
                //     type: 'page',
                //     functions: [
                //         {
                //             label: t('common.view'),
                //             code: PermissionCodeEnum.ActivityCouponView,
                //             checked: true
                //         },
                //         {
                //             label: t('common.replay'),
                //             code: PermissionCodeEnum.ActivityCouponReplay,
                //             checked: true
                //         },
                //         {
                //             label: t('common.editText'),
                //             code: PermissionCodeEnum.ActivityCouponEdit,
                //             checked: true
                //         }
                //     ]
                // }
            ]
        },
        {
            label: t('pay.center'),
            icon: 'kopic-zhifuzhongxin',
            code: PermissionCodeEnum.Payment,
            fromRouteCode: PermissionCodeEnum.App,
            functions: [],
            children: [
                {
                    label: t('pay.methods'),
                    code: PermissionCodeEnum.PaymentType,
                    type: 'page',
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.PaymentTypeView,
                            checked: true
                        },
                        {
                            label: t('common.editText'),
                            code: PermissionCodeEnum.PaymentTypeEdit,
                            checked: true
                        }
                    ]
                },
                {
                    label: t('pay.sort'),
                    code: PermissionCodeEnum.PaymentSort,
                    type: 'page',
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.PaymentSortView,
                            checked: true
                        },
                        {
                            label: t('common.editText'),
                            code: PermissionCodeEnum.PaymentSortEdit,
                            checked: true
                        }
                    ]
                },
                {
                    label: t('pay.config'),
                    code: PermissionCodeEnum.PaymentConfig,
                    type: 'page',
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.PaymentConfigView,
                            checked: true
                        },
                        {
                            label: t('sys.store.config'),
                            code: PermissionCodeEnum.PaymentConfigEdit,
                            checked: true
                        }
                    ]
                }
            ]
        },
        {
            label: t('sys.store.siteDesign'),
            icon: 'kopic-zaixianshangcheng',
            code: PermissionCodeEnum.Store,
            fromRouteCode: PermissionCodeEnum.App,
            children: [
                {
                    label: t('store.config'),
                    code: PermissionCodeEnum.StoreDomain,
                    type: 'page',
                    functions: [
                        {
                            label: t('change.domain'),
                            code: PermissionCodeEnum.StoreDomainChange,
                            checked: true
                        },
                        {
                            label: t('sys.project.domain.addDomain'),
                            code: PermissionCodeEnum.StoreDomainAdd,
                            checked: true
                        },
                        {
                            label: t('change.visitor.whiteCountry'),
                            code: PermissionCodeEnum.StoreVisitorCountryChange,
                            checked: true
                        },
                        {
                            label: t('change.register.pay'),
                            code: PermissionCodeEnum.StoreRegisterLocalPayChange,
                            checked: true
                        }
                    ]
                },
                {
                    label: t('page.config'),
                    code: PermissionCodeEnum.StorePage,
                    type: 'page',
                    functions: [
                        {
                            label: t('sys.project.addPage'),
                            code: PermissionCodeEnum.StorePageAdd,
                            checked: true
                        },
                        {
                            label: t('delete.page'),
                            code: PermissionCodeEnum.StorePageDelete,
                            checked: true
                        }
                    ]
                },
                {
                    label: t('user.manage'),
                    code: PermissionCodeEnum.StoreUser,
                    type: 'page',
                    functions: [
                        {
                            label: t('common.saveText'),
                            code: PermissionCodeEnum.StoreUserSave,
                            checked: true
                        }
                    ]
                },
                {
                    label: t('viewSource.manage'),
                    code: PermissionCodeEnum.StoreFlowOrigin,
                    type: 'page',
                    functions: [
                        {
                            label: t('add.flow'),
                            code: PermissionCodeEnum.StoreFlowOriginAdd,
                            checked: true
                        },
                        {
                            label: t('delete.source'),
                            code: PermissionCodeEnum.StoreFlowOriginDelete,
                            checked: true
                        }
                    ]
                }
            ],
            functions: [
                {
                    label: t('common.view'),
                    code: PermissionCodeEnum.StoreView,
                    checked: true
                },
                {
                    label: t('common.editText'),
                    code: PermissionCodeEnum.StoreEdit,
                    checked: true
                },
                {
                    label: t('common.deploy'),
                    code: PermissionCodeEnum.StorePublish,
                    checked: true
                }
            ]
        },
        {
            label: t('sys.store.userGroup'),
            code: PermissionCodeEnum.UserGroup,
            icon: 'kopic-yonghuqunti',
            fromRouteCode: PermissionCodeEnum.App,
            children: [],
            functions: [
                {
                    label: t('common.view'),
                    code: PermissionCodeEnum.UserGroupView,
                    type: 'button',
                    checked: true
                },
                {
                    label: t('common.creates'),
                    code: PermissionCodeEnum.UserGroupCreate,
                    type: 'button',
                    checked: true
                }
            ]
        },
        {
            label: t('sys.store.setting'),
            icon: 'kopic-yingyongshezhi',
            code: PermissionCodeEnum.App,
            functions: [],
            children: [
                {
                    label: t('sys.project.setting.generalSetting'),
                    code: PermissionCodeEnum.AppDetail,
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.AppDetailView,
                            checked: true,
                        },
                        {
                            label: t('common.editText'),
                            code: PermissionCodeEnum.AppDetailEdit,
                            checked: true,
                        }
                    ]
                },
                {
                    label: 'webhooks',
                    code: PermissionCodeEnum.AppWebhook,
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.AppWebhookView,
                            checked: true,
                        },
                        {
                            label: t('common.editText'),
                            code: PermissionCodeEnum.AppWebhookEdit,
                            checked: true,
                        }
                    ]
                },
                {
                    label: t('sys.project.priceTamplate'),
                    code: PermissionCodeEnum.PriceTemplate,
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.PriceTemplateView,
                            checked: true,
                        },
                        {
                            label: t('common.editText'),
                            code: PermissionCodeEnum.PriceTemplateEdit,
                            checked: true,
                        }
                    ]
                },
                {
                    label: t('auto.update.price'),
                    code: PermissionCodeEnum.AutoUpdatePrice,
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.AutoUpdatePriceView,
                            checked: true,
                        },
                        {
                            label: t('common.editText'),
                            code: PermissionCodeEnum.AutoUpdatePriceEdit,
                            checked: true,
                        }
                    ]
                },
                {
                    label: t('risk.manage'),
                    code: PermissionCodeEnum.RiskManage,
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.RiskManageView,
                            checked: true,
                        },
                        {
                            label: t('common.editText'),
                            code: PermissionCodeEnum.RiskManageEdit,
                            checked: true,
                        }
                    ]
                },
                {
                    label: t('language.library'),
                    code: PermissionCodeEnum.LanguageManage,
                    functions: [
                        {
                            label: t('common.view'),
                            code: PermissionCodeEnum.LanguageManageView,
                            checked: true,
                        },
                        {
                            label: t('common.editText'),
                            code: PermissionCodeEnum.LanguageManageEdit,
                            checked: true,
                        }
                    ]
                }
            ]
        }
    ]
}